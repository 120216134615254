:root {
  --themePrimary: '#9a9f78';
  --themeSecondary: '#323130';
  --themeTertiary: '#c1c5a7';

  --themeLighterAlt: '#fbfbf9';
  --themeLighter: '#eeefe6';
  --themeLight: '#e0e2d1';
  --themeDarkAlt: '#8a8e6b';
  --themeDark: '#74785a';
  --themeDarker: '#565942';
  --neutralLighterAlt: '#e2dac7';
  --neutralLighter: '#ded7c3';
  --neutralLight: '#d5cebb';
  --neutralQuaternaryAlt: '#c6c0af';
  --neutralQuaternary: '#bdb7a7';
  --neutralTertiaryAlt: '#b6b0a0';
  --neutralTertiary: '#a19f9d';
  --neutralSecondary: '#605e5c';
  --neutralSecondaryAlt: '#8a8886';
  --neutralPrimaryAlt: '#3b3a39';
  --neutralPrimary: '#323130';
  --neutralDark: '#201f1e';
  --black: '#000000';
  --white: '#e9e1cc';
  --textColor: #323130;

  /* --background: radial-gradient(circle at 18.7% 37.8%, #eeefe6 0%, #fff 90%); */
}

main {
  min-height: 75vh;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 576px) {
  .container {
    flex-direction: column;
  }
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: var(--textColor);
  background: var(--background);
}

a {
  color: inherit;
  /* text-decoration: none; */
}

@media (prefers-color-scheme: light) {
  html {
    color-scheme: light;
  }
}

@media (min-width: 768px) {
  .hover-underline-animation {
    display: inline-block;
    position: relative;
  }

  .hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: var(--textColor);
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
  }

  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.fadein-1 {
  animation: fadein 1s forwards;
}

.fadein-2 {
  opacity: 0;
  animation: fadein 1s forwards 0.5s;
}

.fadein-3 {
  opacity: 0;
  animation: fadein 1s forwards 1s;
}

.fadein-4 {
  opacity: 0;
  animation: fadein 1s forwards 1.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);

  }
}

.text-left {
  text-align: left;
}

.text-justify {
  text-align: justify;
}


h5 {
  color: var(--textColor);
}

p {
  color: var(--textColor);
}

.floating-svgs-img {
  position: relative;
  bottom: -20vh;
  animation: float 10s infinite linear;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100vh);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.puff-in-center {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
  animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-1-29 10:10:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.comment {
  float: left;
  border-radius: 5px;
}

.comment h4, .comment span, .darker h4, .darker span {
  display: inline;
}

.comment p, .comment span, .darker p, .darker span {
  color: rgb(184, 183, 183);
}

.bento {
  border-radius: 20px;
  background: #fff;
  box-shadow: 20px 20px 60px #e0e0e0;
  overflow: hidden;
}

.bento-gradient {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.share-button {
  height: 50px !important;
  width: 50px !important;
}